<template>
    <div>
        <h1 class="text-xs-center pb-5 sz-kath-title">Kirchgemeinden</h1>
        <Table></Table>
    </div>
</template>

<script>
    import Table from '../components/KirchengemeindenTable.vue';
    export default {
        name: "Kirchengemeinden",
        components: {
            Table
        },
        beforeCreate() {
            this.$store.dispatch('setViewSizeToNormal');
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
    }
</script>

<style scoped>

</style>